import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { withStyles } from "@material-ui/core/styles"
import styles from "../../components/styles"

const style = theme => ({
  ...styles(theme),
  manufacturerTable:{
    '& dd':{
      '&:nth-child(1)': {
        width: '14%'
      }
    }
  }
})


const guidesCableManufacturer = ({classes}) => {
  return (
    <Layout>
      <SEO title="Коды кабельных заводов России и СНГ, производителей кабеля и производителей кабеля и провода" />
      <div>
        <span><Link to={`/guides/`}>Справочник</Link></span>
        <span> > </span>
        <span>Коды кабельных заводов России и стран СНГ</span>
      </div>
      <main>
        <h1>Коды кабельных заводов России и стран СНГ</h1>
        <p>В некоторых случаях, в маркировке кабеля или провода отсутствует наименование завода производителя. Данная таблица поможет идентифицировать продукцию.</p>
        <div className={[classes.AsTable+ ' ' +classes.manufacturerTable]}>
          <dl className={classes.AsTableHead}><dd><b>Код</b></dd><dd><b>Наименование предприятия</b></dd><dd><b>Страна, город</b></dd></dl>
          <dl><dd>К01</dd><dd>Электрокабель Кольчугинский завод (ЭКЗ), ОАО </dd><dd>Россия, Кольчугино </dd></dl>
          <dl><dd>К02</dd><dd>Рыбинсккабель, ОАО </dd><dd>Россия, Рыбинск </dd></dl>
          <dl><dd>К03</dd><dd>Кирскабель, АО</dd><dd>Россия, Кирс </dd></dl>
          <dl><dd>К04</dd><dd>Сарансккабель, ОАО </dd><dd>Россия, Саранск </dd></dl>
          <dl><dd>К05</dd><dd>Чувашкабель, ОАО </dd><dd>Россия, Чебоксары </dd></dl>
          <dl><dd>К06</dd><dd>Завод Эмальпровод, ООО </dd><dd>Россия, Томск </dd></dl>
          <dl><dd>К07</dd><dd>Режевской кабельный завод, ЗАО (Электра)</dd><dd>Россия, Реж </dd></dl>
          <dl><dd>К08</dd><dd>Металлист, АОЗТ</dd><dd>Россия, Колпашево</dd></dl>
          <dl><dd>К09</dd><dd>Камкабель, ОАО </dd><dd>Россия, Пермь </dd></dl>
          <dl><dd>К10</dd><dd>Севкабель, ОАО </dd><dd>Россия, Санкт-Петербург </dd></dl>
          <dl><dd>К11</dd><dd>Москабельмет, ЗАО</dd><dd>Россия, Москва </dd></dl>
          <dl><dd>К12</dd><dd>Электропровод, ЗАО</dd><dd>Россия, Москва </dd></dl>
          <dl><dd>К13</dd><dd>Подольсккабель НП, ЗАО</dd><dd>Россия, Подольск </dd></dl>
          <dl><dd>К14</dd><dd>Микропровод, ОАО</dd><dd>Россия, Подольск</dd></dl>
          <dl><dd>К15</dd><dd>Марпосадкабель, ОАО</dd><dd>Россия, Мариинский посад</dd></dl>
          <dl><dd>К16</dd><dd>Волгакабель, ОАО </dd><dd>Россия, Самара </dd></dl>
          <dl><dd>К17</dd><dd>Самарская кабельная компания, ЗАО</dd><dd>Россия, Самара </dd></dl>
          <dl><dd>К18</dd><dd>Уфимкабель, ОАО </dd><dd>Россия, Уфа </dd></dl>
          <dl><dd>К19</dd><dd>Уралкабель, ЗАО</dd><dd>Россия, Екатеринбург </dd></dl>
          <dl><dd>К20</dd><dd>Амуркабель, ОАО </dd><dd>Россия, Хабаровск </dd></dl>
          <dl><dd>К21</dd><dd>Андижанкабель СП, ОАО</dd><dd>Узбекистан, Ханабад </dd></dl>
          <dl><dd>К22</dd><dd>Иркутсккабель, ОАО </dd><dd>Россия, Шелехов </dd></dl>
          <dl><dd>К23</dd><dd>Донбасскабель, ОАО </dd><dd>Украина, Донецк </dd></dl>
          <dl><dd>К24</dd><dd>Укркабель, ОАО </dd><dd>Украина, Киев </dd></dl>
          <dl><dd>К25</dd><dd>Каменецкподольсккабель, ОАО </dd><dd>Украина, Каменец-Подольский </dd></dl>
          <dl><dd>К26</dd><dd>Пахтакабель, завод</dd><dd>Узбекистан, Пахта</dd></dl>
          <dl><dd>К27</dd><dd>Азовкабель ПО</dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К28</dd><dd>Южкабель, ЗАО</dd><dd>Украина, Харьков </dd></dl>
          <dl><dd>К29</dd><dd>Одескабель, ОАО </dd><dd>Украина, Одесса </dd></dl>
          <dl><dd>К30</dd><dd>Гомелькабель, ОАО </dd><dd>Беларусь, Гомель </dd></dl>
          <dl><dd>К31</dd><dd>Беларускабель, ОАО </dd><dd>Беларусь, Мозырь </dd></dl>
          <dl><dd>К32</dd><dd>Автопровод</dd><dd>Беларусь, Щучин </dd></dl>
          <dl><dd>К33</dd><dd>Азовкабель-Донэлектро, ООО </dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К34</dd><dd>Армавирский завод связи, ДЗАО </dd><dd>Россия, Армавир </dd></dl>
          <dl><dd>К35</dd><dd>Эмальпровод, завод</dd><dd>Южная осетия, Цхинвали</dd></dl>
          <dl><dd>К36</dd><dd>Грузкабель, АО</dd><dd>Грузия, Зестафони </dd></dl>
          <dl><dd>К37</dd><dd>Азеркабель</dd><dd>Азербайджан, Мингечаур </dd></dl>
          <dl><dd>К38</dd><dd>Леткабелис, АО</dd><dd>Литва, Паневежис </dd></dl>
          <dl><dd>К39</dd><dd>Молдавкабель</dd><dd>Молдавия, Бендеры </dd></dl>
          <dl><dd>К40</dd><dd>Каиндинский кабельный завод, АООТ</dd><dd>Киргизия, Каинда п.г.т.</dd></dl>
          <dl><dd>К41</dd><dd>Таджиккабель, АО </dd><dd>Таджикистан, Душанбе </dd></dl>
          <dl><dd>К42</dd><dd>Таджиккабель, АО </dd><dd>Таджикистан, Душанбе </dd></dl>
          <dl><dd>К43</dd><dd>Гегама, АООТ</dd><dd>Армения, Гавар </dd></dl>
          <dl><dd>К44</dd><dd>Туркменкабель ПО</dd><dd>Туркменистан, Ашхабад </dd></dl>
          <dl><dd>К45</dd><dd>ЭКСИ, АО</dd><dd>Эстония, Таллин </dd></dl>
          <dl><dd>К46</dd><dd>Экспокабель, ОАО </dd><dd>Россия, Подольск </dd></dl>
          <dl><dd>К47</dd><dd>Роскабель, АО</dd><dd>Россия, Ростов-На Дону </dd></dl>
          <dl><dd>К48</dd><dd>ОП ОКБ КП </dd><dd>Россия, Мытищи </dd></dl>
          <dl><dd>К49</dd><dd>Спектр ПО</dd><dd>Узбекистан, Ташкент </dd></dl>
          <dl><dd>К50</dd><dd>Псковкабель, ОАО </dd><dd>Россия, Псков </dd></dl>
          <dl><dd>К51</dd><dd>АРИНА, АО</dd><dd>Россия, Армавир </dd></dl>
          <dl><dd>К52</dd><dd>Электрокабель, ЗАО</dd><dd>Россия, Новосибирск </dd></dl>
          <dl><dd>К53</dd><dd>Карелагропромэнерго, ООО </dd><dd>Россия, Петрозаводск </dd></dl>
          <dl><dd>К54</dd><dd>Энергокомплекс УНПП</dd><dd>Россия, Иркутск </dd></dl>
          <dl><dd>К55</dd><dd>Казэнергокабель, АОА</dd><dd>Казахстан, Павлодар </dd></dl>
          <dl><dd>К56</dd><dd>Сибкабель, ЗАО</dd><dd>Россия, Томск </dd></dl>
          <dl><dd>К57</dd><dd>АРМНИИКП СП, ЗАО</dd><dd>Армения, Ереван </dd></dl>
          <dl><dd>К58</dd><dd>Рыбинскэлектрокабель, ООО </dd><dd>Россия, Рыбинск </dd></dl>
          <dl><dd>К59</dd><dd>УсольеВСЭМкабель, ООО </dd><dd>Россия, Усолье-Сибирское-2 </dd></dl>
          <dl><dd>К60</dd><dd>Контур, ЗАО</dd><dd>Россия, Кемерово </dd></dl>
          <dl><dd>К61</dd><dd>Дальэлектромонтаж, ЗАО</dd><dd>Россия, Хабаровск </dd></dl>
          <dl><dd>К62</dd><dd>Торговый дом Паритет-ЛТД, ООО </dd><dd>Россия, Подольск </dd></dl>
          <dl><dd>К63</dd><dd>Сибтехагро, ЗАО</dd><dd>Россия, Томск </dd></dl>
          <dl><dd>К64</dd><dd>Узкабель ПО</dd><dd>Узбекистан, Ташкент </dd></dl>
          <dl><dd>К65</dd><dd>Партнер, ООО </dd><dd>Россия, Рубцовск </dd></dl>
          <dl><dd>К67</dd><dd>Кавказкабель, ЗАО</dd><dd>Россия, Прохладный </dd></dl>
          <dl><dd>К68</dd><dd>Казахстанкабель, АПО</dd><dd>Казахстан, Семипалатинск </dd></dl>
          <dl><dd>К69</dd><dd>ЭЛКАБ, ТОО</dd><dd>Россия, Заречный, Свердловская обл. </dd></dl>
          <dl><dd>К70</dd><dd>Манычкабель, ЗАО</dd><dd>Россия, Пролетарск </dd></dl>
          <dl><dd>К71</dd><dd>ВНИИКП, ОАО</dd><dd>Россия, Москва</dd></dl>
          <dl><dd>К72</dd><dd>Строительно-монтажная лаборатория, ЗАО</dd><dd>Россия, Новосибирск </dd></dl>
          <dl><dd>К73</dd><dd>НИКИ, ОАО </dd><dd>Россия, Томск </dd></dl>
          <dl><dd>К74</dd><dd>ВНИИКП, ОАО</dd><dd>Россия, Подольск</dd></dl>
          <dl><dd>К75</dd><dd>Азовская кабельная компания, ООО </dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К76</dd><dd>Государственное унитарное предприятие ОКБ КП</dd><dd>Россия, Мытищи</dd></dl>
          <dl><dd>К77</dd><dd>Востокпромсвязьмонтаж, АО</dd><dd>Россия, Красноярск </dd></dl>
          <dl><dd>К78</dd><dd>УкрНИИКП</dd><dd>Украина, Бердянск</dd></dl>
          <dl><dd>К79</dd><dd>Людиновокабель, ЗАО</dd><dd>Россия, Людиново </dd></dl>
          <dl><dd>К80</dd><dd>АрмНИИКП, ЗАО</dd><dd>Армения, Ереван</dd></dl>
          <dl><dd>К81</dd><dd>Черногорскэлектромонтаж, ООО </dd><dd>Россия, Черногорск </dd></dl>
          <dl><dd>К82</dd><dd>Облагропромэнерго, МХАП</dd><dd>Беларусь, Гомель </dd></dl>
          <dl><dd>К83</dd><dd>Брянский химический завод ФГУП</dd><dd>Россия, Сельцо, Брянская обл. </dd></dl>
          <dl><dd>К84</dd><dd>Северовостокэлектро-монтаж, ОАО </dd><dd>Россия, Красноярск </dd></dl>
          <dl><dd>К85</dd><dd>Андромеда, АОЗТ</dd><dd>Россия, Псков </dd></dl>
          <dl><dd>К86</dd><dd>Катех, ООО </dd><dd>Украина, Коцюбинский пос, Киевская обл. </dd></dl>
          <dl><dd>К87</dd><dd>Москабельмет-Фуджикура, ЗАО</dd><dd>Россия, Москва</dd></dl>
          <dl><dd>К88</dd><dd>Сатурн, ТОО</dd><dd>Россия, Набережные Челны </dd></dl>
          <dl><dd>К89</dd><dd>Герос-Кабель, ЗАО </dd><dd>Россия, Пермь </dd></dl>
          <dl><dd>К90</dd><dd>Электра, ТОО</dd><dd>Россия, Пермь </dd></dl>
          <dl><dd>К91</dd><dd>Новосибкабель, ЗАО</dd><dd>Россия, Новосибирск </dd></dl>
          <dl><dd>К92</dd><dd>Ореол, ООО </dd><dd>Россия, Ростов-На-Дону </dd></dl>
          <dl><dd>К93</dd><dd>Информсистема, НПП</dd><dd>Россия, Ростов-на-Дону </dd></dl>
          <dl><dd>К94</dd><dd>Кавэлектромонтаж, АО</dd><dd>Россия, Ростов-на-Дону </dd></dl>
          <dl><dd>К95</dd><dd>Гальва Лтд, АО </dd><dd>Россия, Магнитогорск </dd></dl>
          <dl><dd>К96</dd><dd>Энергокомплект ПО</dd><dd>Беларусь, Витебск </dd></dl>
          <dl><dd>К97</dd><dd>Рыбинсктехнокабель, ЗАО</dd><dd>Россия, Рыбинск </dd></dl>
          <dl><dd>К98</dd><dd>Вика-Энерпрайз ПДП</dd><dd>Узбекистан, Ташкент </dd></dl>
          <dl><dd>К99</dd><dd>Спецкабель, НПП</dd><dd>Россия, Москва</dd></dl>
          <dl><dd>К100</dd><dd>Лусент Текнолоджис-Связь- строй-1, АЗО</dd><dd>Россия, Воронеж </dd></dl>
          <dl><dd>К101</dd><dd>Машиностроительная компания, ООО </dd><dd>Россия, Иркутск </dd></dl>
          <dl><dd>К102</dd><dd>Примагропромэнерго, ПО ООО</dd><dd>Россия, Уссурийск</dd></dl>
          <dl><dd>К103</dd><dd>Лукас, ООО </dd><dd>Россия, Барнаул </dd></dl>
          <dl><dd>К104</dd><dd>ГОСНИП, ООО</dd><dd>Беларусь, Бибики пос.</dd></dl>
          <dl><dd>К105</dd><dd>НМУ2 Сибэлектромонтаж, АООТ</dd><dd>Россия, Новосибирск </dd></dl>
          <dl><dd>К106</dd><dd>Электросервис ПК</dd><dd>Россия, Ангарск </dd></dl>
          <dl><dd>К107</dd><dd>Азовкабель-Связькомплект-электро, ООО </dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К108</dd><dd>Кабель Украина ТФ, ООО </dd><dd>Украина, Чернигов </dd></dl>
          <dl><dd>К109</dd><dd>Алюр, ООО </dd><dd>Россия, Великие Луки </dd></dl>
          <dl><dd>К110</dd><dd>Объектив, ОАО </dd><dd>Россия, Новоржев </dd></dl>
          <dl><dd>К111</dd><dd>Алтайкабель, ООО </dd><dd>Россия, Поспелиха </dd></dl>
          <dl><dd>К112</dd><dd>Фирма ВостокКабель, ООО </dd><dd>Россия, Иркутск </dd></dl>
          <dl><dd>К113</dd><dd>Азовкабель-Приват, ООО </dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К114</dd><dd>Родонит-Техноцентр, ЗАО</dd><dd>Россия, Москва </dd></dl>
          <dl><dd>К115</dd><dd>ЭЛЕКТОН, ООО </dd><dd>Россия, Киров </dd></dl>
          <dl><dd>К116</dd><dd>ЭНЕРГОРЕМОНТ, ООО </dd><dd>Россия, Йошкар-Ола </dd></dl>
          <dl><dd>К117</dd><dd>Сарансккабель-Оптика, ООО </dd><dd>Россия, Саранск </dd></dl>
          <dl><dd>К118</dd><dd>Кабель-Арсенал, ООО </dd><dd>Россия, Климовск </dd></dl>
          <dl><dd>К119</dd><dd>Монтажное управление 78, ОАО</dd><dd>Россия, Курган </dd></dl>
          <dl><dd>К120</dd><dd>Макс +, ООО </dd><dd>Россия, Щербинка </dd></dl>
          <dl><dd>К121</dd><dd>Энергокабель, ЗАО</dd><dd>Россия, Электроугли </dd></dl>
          <dl><dd>К122</dd><dd>Карат ПКФ, ООО </dd><dd>Россия, Бийск </dd></dl>
          <dl><dd>К123</dd><dd>БРЭКС, ООО </dd><dd>Россия, Брянск </dd></dl>
          <dl><dd>К124</dd><dd>Уралэлектромонтаж, ОАО</dd><dd>Россия, Екатеринбург </dd></dl>
          <dl><dd>К125</dd><dd>Бердянский кабельный завод, ЗАО</dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К126</dd><dd>ПОЛИМЕТ, ЗАО</dd><dd>Россия, Целина </dd></dl>
          <dl><dd>К127</dd><dd>СКЗ, ЗАО</dd><dd>Россия, Смоленск </dd></dl>
          <dl><dd>К128</dd><dd>Балтик-Кабель, ООО </dd><dd>Россия, Советск </dd></dl>
          <dl><dd>К129</dd><dd>Азовкабель, ЗАО</dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К130</dd><dd>Агролизинг </dd><dd>Россия, Ждановский пос, Нижегородская обл. </dd></dl>
          <dl><dd>К131</dd><dd>ПРОМСТРОЙКАБЕЛЬ, ЗАО</dd><dd>Россия, Трехгорный </dd></dl>
          <dl><dd>К132</dd><dd>Вим-Кабель, ООО </dd><dd>Россия, п. Балакирево, Владимирская обл. </dd></dl>
          <dl><dd>К133</dd><dd>Уралтранс СП, ООО</dd><dd>Россия, Челябинск </dd></dl>
          <dl><dd>К134</dd><dd>Транзит-Кабель, ООО </dd><dd>Россия, Пермь </dd></dl>
          <dl><dd>К135</dd><dd>Томсккабель, ЗАО</dd><dd>Россия, Томск </dd></dl>
          <dl><dd>К136</dd><dd>Конкорд, ООО </dd><dd>Россия, Смоленск </dd></dl>
          <dl><dd>К137</dd><dd>КАТЭЛ, ЗАО</dd><dd>Россия, Тверь </dd></dl>
          <dl><dd>К138</dd><dd>СПКБ Техно, ЗАО</dd><dd>Россия, Подольск </dd></dl>
          <dl><dd>К139</dd><dd>Кавказкабель ТМ, ООО </dd><dd>Россия, Прохладный </dd></dl>
          <dl><dd>К140</dd><dd>Электрокабель, ООО </dd><dd>Украина, Бешенковичи п.г.т., Витебская обл. </dd></dl>
          <dl><dd>К141</dd><dd>Эвиком, ДТПРУП </dd><dd>Беларусь, Витебск </dd></dl>
          <dl><dd>К142</dd><dd>Поиск-1 ЧУПП</dd><dd>Беларусь, Чаусы </dd></dl>
          <dl><dd>К143</dd><dd>Волгодонской кабельный завод</dd><dd>Россия, Волгодонск </dd></dl>
          <dl><dd>К144</dd><dd>Арзамасское опытное кабельное производство, ЗАО</dd><dd>Россия, Арзамас</dd></dl>
          <dl><dd>К145</dd><dd>Комприбор ПКФ, ООО</dd><dd>Россия, Набережные Челны </dd></dl>
          <dl><dd>К146</dd><dd>СмолКабель, ООО </dd><dd>Россия, Сафоново </dd></dl>
          <dl><dd>К147</dd><dd>Интеркабель, ООО </dd><dd>Украина, с. Дмитриевка, Киевская обл. </dd></dl>
          <dl><dd>К148</dd><dd>Электросетьизоляция, ООО </dd><dd>Россия, Химки </dd></dl>
          <dl><dd>К149</dd><dd>ТЕМБР, ООО </dd><dd>Россия, Унеча </dd></dl>
          <dl><dd>К150</dd><dd>Промкабель, ООО </dd><dd>Россия, Ижевск </dd></dl>
          <dl><dd>К151</dd><dd>Дмитров-Кабель, ООО </dd><dd>Россия, Дмитров </dd></dl>
          <dl><dd>К152</dd><dd>ТУМЕН, МЧП</dd><dd>Украина, Одесса </dd></dl>
          <dl><dd>К153</dd><dd>Калужский кабельный завод, ООО </dd><dd>Россия, п. Жилетово, Калужская обл. </dd></dl>
          <dl><dd>К154</dd><dd>Восток-Альфа ПГ, ООО </dd><dd>Россия, Новосибирск </dd></dl>
          <dl><dd>К155</dd><dd>Быткабель М, ООО </dd><dd>Россия, Москва  </dd></dl>
          <dl><dd>К156</dd><dd>Байкалкабель, ЗАО</dd><dd>Россия, Иркутск </dd></dl>
          <dl><dd>К157</dd><dd>Воронежкабель ПКФ, ООО</dd><dd>Россия, Воронеж </dd></dl>
          <dl><dd>К158</dd><dd>Кубаньпровод, ЗАО</dd><dd>Россия, Краснодар </dd></dl>
          <dl><dd>К159</dd><dd>Эксвайер, ООО </dd><dd>Россия, Калининград  </dd></dl>
          <dl><dd>К160</dd><dd>Можайский кабель, ООО </dd><dd>Россия, Можайск </dd></dl>
          <dl><dd>К161</dd><dd>Севморкабель, ООО </dd><dd>Россия, Санкт-Петербург </dd></dl>
          <dl><dd>К162</dd><dd>Сибирь-Кабель, ООО</dd><dd>Россия, Новосибирск</dd></dl>
          <dl><dd>К163</dd><dd>Липаркабель, ООО </dd><dd>Украина, Новомосковск </dd></dl>
          <dl><dd>К164</dd><dd>Донкабель, ООО </dd><dd>Россия, Пролетарск </dd></dl>
          <dl><dd>К165</dd><dd>БОНА, ООО </dd><dd>Россия, Челябинск </dd></dl>
          <dl><dd>К166</dd><dd>HI-TECH KABEL СП</dd><dd>Узбекистан, Гулистан пос, Ташкентская обл. </dd></dl>
          <dl><dd>К167</dd><dd>Агрокабель, ЗАО </dd><dd>Россия, Окуловка </dd></dl>
          <dl><dd>К168</dd><dd>Фирма Элтеп, ООО </dd><dd>Россия, Лосино-Петровский </dd></dl>
          <dl><dd>К169</dd><dd>БелЮжкабель, ЗАО</dd><dd>Россия, Белгород </dd></dl>
          <dl><dd>К170</dd><dd>ТЕПЛОСКАТ, НТЦ</dd><dd>Россия, Подольск</dd></dl>
          <dl><dd>К171</dd><dd>Pakhtakabel, ООО</dd><dd>Узбекистан, Ташкент </dd></dl>
          <dl><dd>К172</dd><dd>Термопровод, ЗАО</dd><dd>Россия, Подольск</dd></dl>
          <dl><dd>К173</dd><dd>Мемотерм-ММ 7, ЗАО</dd><dd>Россия, Москва</dd></dl>
          <dl><dd>К174</dd><dd>Ланкабель, ООО </dd><dd>Россия, Павловский посад </dd></dl>
          <dl><dd>К175</dd><dd>Кунгурское электротехническоское предприятие, ООО </dd><dd>Россия, Кунгур </dd></dl>
          <dl><dd>К176</dd><dd>Электрокабель НН, ООО</dd><dd>Россия, с. Безводное, Нижегородская обл. </dd></dl>
          <dl><dd>К177</dd><dd>Озёрский кабельный завод, ООО </dd><dd>Россия, Озёрск </dd></dl>
          <dl><dd>К178</dd><dd>Нексанс СНГ, ООО</dd><dd>Россия, Углич </dd></dl>
          <dl><dd>К179</dd><dd>Комплектмаш ТД, ЗАО </dd><dd>Россия, Екатеринбург </dd></dl>
          <dl><dd>К180</dd><dd>Камский кабель, ООО </dd><dd>Россия, Пермь  </dd></dl>
          <dl><dd>К181</dd><dd>АВС-Инвест, ЗАО </dd><dd>Россия, Ставрополь </dd></dl>
          <dl><dd>К182</dd><dd>СИНТЕК-ПРО ЧП</dd><dd>Украина, Киев </dd></dl>
          <dl><dd>К183</dd><dd>АЗОВ технолоджи електрик, ООО </dd><dd>Украина, Бердянск </dd></dl>
          <dl><dd>К184</dd><dd>Элтека, ООО </dd><dd>Россия, Москва </dd></dl>
          <dl><dd>К185</dd><dd>Псковгеокабель, ООО</dd><dd>Россия, Псков</dd></dl>
          <dl><dd>К186</dd><dd>ТАТКАБЕЛЬ, ООО </dd><dd>Россия, Казань</dd></dl>
          <dl><dd>К187</dd><dd>Электродаркабель, ООО </dd><dd>Россия, Павловская ст-ца</dd></dl>
        </div>
      </main>
    </Layout>
  )
}

guidesCableManufacturer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(guidesCableManufacturer)