const styles = (theme) => ({
  list: {
    breakInside: 'avoid',
    [theme.breakpoints.down('xs')]: {
      columnCount: "1"
    },
    [theme.breakpoints.up('sm')]: {
      columnCount: "2"
    },
    [theme.breakpoints.up('lg')]: {
      columnCount: "3"
    },
    // padding: '0 0 32px 0',
    '& li': {
      margin: '0 0 8px 0'
    }
  },
  //Ul as Table
  AsTable:{
    listStyleType: 'none',
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    overflowX: 'auto',
    borderBottom: '1px solid #e2e9ea',
    borderRight: '1px solid #e2e9ea',
    marginBottom: '16px',
    '& dl':{
      display: 'table-row',
      width: '100%',
      // '& label':{
      //   display: 'table-cell',
      //   width: '14%',
      //   borderTop: '1px solid #e2e9ea',
      //   borderRight: '1px solid #e2e9ea',
      //   borderLeft: '1px solid #e2e9ea',
      //   padding: '3px 5px 3px 5px'
      // },
      '& dd':{
        display: 'table-cell',
        borderTop: '1px solid #e2e9ea',
        borderLeft: '1px solid #e2e9ea',
        padding: '3px 5px 3px 5px'
      },
      '& dt':{
        display: 'table-cell',
        borderTop: '1px solid #e2e9ea',
        borderLeft: '1px solid #e2e9ea',
        padding: '3px 5px 3px 5px',
        fontWeight: '500'
      }
    }
  },
  AsTableHead: {
    // zIndex: '10',
    // width: '100%',
    '& dd, dt':{
      verticalAlign: 'middle',
      position: 'sticky',
      top: '0',
      fontWeight: '700',
      backgroundColor: '#f5f5f5',
    }
  },
  imageBoxGrid:{
    [theme.breakpoints.up('md')]: {
      maxWidth: '200px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '250px'
    },
  },
  imageBox: {
    overflow: 'hidden',
    width: '180px',
    [theme.breakpoints.down('sm')]: {
      height: '178px',
      width: '432px',
      margin: '0 auto 16px auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '281px',
      margin: '0 -16px 16px auto',
    }
  },
  prodBoxGrid: {
    '& ol': {
      marginBlockStart: 0,
      paddingInlineStart: '20px',
      '& li': {
        margin: theme.spacing(0, 0, 2, 0)
      }
    },
    '& h2': {
      margin: '0 0 0.67em 0'
    }
  },
//   list-style-type: none;
// width: 100%;
// display: table;
// table-layout: fixed;
// margin-block-start: 0;
// margin-block-end: 0;
// margin-inline-start: 0px;
// margin-inline-end: 0px;
// padding-inline-start: 0;

  // appBar: {
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  // },
  // toolbar: {
  //   flexWrap: 'wrap',
  // },
  // toolbarTitle: {
  //   flexGrow: 1,
  // },
  // link: {
  //   margin: theme.spacing(1, 1.5),
  // },
  // heroContent: {
  //   padding: theme.spacing(8, 0, 6),
  // },
  // cardHeader: {
  //   backgroundColor:
  //     theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  // },
  // cardPricing: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'baseline',
  //   marginBottom: theme.spacing(2),
  // },
  // footer: {
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   marginTop: theme.spacing(8),
  //   paddingTop: theme.spacing(3),
  //   paddingBottom: theme.spacing(3),
  //   [theme.breakpoints.up('sm')]: {
  //     paddingTop: theme.spacing(6),
  //     paddingBottom: theme.spacing(6),
  //   },
})

export default styles